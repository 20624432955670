import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{attrs:{"grow":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.items),function(item){return _c(VTabItem,{key:item},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_vm._v(_vm._s(_vm.tabItemText))])],1)],1)}),1),_c('div',{staticClass:"text-center pa-4"},[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.preTab}},[_vm._v(" Previous ")]),_c(VDivider,{staticClass:"mx-4",attrs:{"vertical":""}}),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.nextTab}},[_vm._v(" Next ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }