import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTab,[_vm._v("Tab One")]),_c(VTab,[_vm._v("Tab Two")]),_c(VTab,[_vm._v("Tab Three")])],1),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((3),function(item){return _c(VTabItem,{key:item},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pb-0",domProps:{"textContent":_vm._s(_vm.tabItemContent)}})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }