import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{attrs:{"icons-and-text":"","grow":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTab,[_vm._v(" Recents "),_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPhone))])],1),_c(VTab,[_vm._v(" Favorites "),_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiHeartOutline))])],1),_c(VTab,[_vm._v(" Nearby "),_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiAccountBoxOutline))])],1)],1),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((3),function(i){return _c(VTabItem,{key:i},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_vm._v(_vm._s(_vm.tabItemText))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }